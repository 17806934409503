Alpine.data("slideOver", (id) => {
  let _this;
  return {
    isOpen: false,
    _id: id,
    removeAnimationClassDelay: 4000,
    serverResponseInMaxMs: 2500,
    missClickMainContainerClass: ["animate-shake"],
    missClickCloseButtonContainerClass: [
      "animate-pulse",
      "scale-150",
      "text-red-500",
      "dark:text-red-500",
    ],
    mainContainerClassList: null,
    closeButtonContainerClassList: null,
    expectingResponse: false,
    init() {
      _this = this;

      _this.registerSlideOverEvents();

      _this.mainContainerClassList = this.$refs.mainContainer.classList;
      _this.closeButtonContainerClassList =
        this.$refs.closeButtonContainer.classList;

      logger.info(
        null,
        `Component: registered\n       ↳ Type → slide-over\n       ↳ Id → ${id}\n       ↳ Listener → slide-over.${id}.state\n\n\n`,
      );
    },
    registerSlideOverEvents() {
      _this.$wire.on(`slide-over.${_this.getSlideOverId()}.open`, () => {
        _this.expectingResponse = false;

        _this.isOpen = true;

        _this.dispatchState("opened", {});
      });

      _this.$wire.on(`slide-over.${_this.getSlideOverId()}.close`, () => {
        _this.expectingResponse = false;

        _this.removeMissClickAnimationFinished();

        _this.isOpen = false;

        _this.dispatchState("closed", {});
      });
    },
    open() {
      _this.dispatchState("open");

      _this.expectingResponseHandler(_this.isOpen);

      _this.isOpen = false;
    },
    close() {
      _this.dispatchState("close");

      _this.expectingResponseHandler(_this.isOpen);

      _this.isOpen = false;
    },
    expectingResponseHandler(previousValue) {
      _this.expectingResponse = true;

      setTimeout(
        () => {
          if (_this.expectingResponse) {
            cmch.throw("WJS1000");

            _this.isOpen = previousValue;
          }
        },
        _this.serverResponseInMaxMs,
        previousValue,
      );
    },
    dispatchState(newState) {
      let target = `slide-over.${_this.getSlideOverId()}.state`;

      _this.$dispatch(target, { state: newState });

      logger.info(null, `dispatched "${newState}" to "${target}"`);

      _this.updatedAt = Date.now();
    },
    getSlideOverId() {
      if (!_this._id) {
        cmch.throw("EAB1000");

        return;
      }

      return _this._id;
    },
    removeMissClickAnimationFinished() {
      _this.mainContainerClassList.remove(..._this.missClickMainContainerClass);
      _this.closeButtonContainerClassList.remove(
        ..._this.missClickCloseButtonContainerClass,
      );
    },
    startMissClickAnimation() {
      _this.mainContainerClassList.add(..._this.missClickMainContainerClass);
      _this.closeButtonContainerClassList.add(
        ..._this.missClickCloseButtonContainerClass,
      );

      setTimeout(
        _this.removeMissClickAnimationFinished,
        _this.removeAnimationClassDelay,
      );
    },
  };
});
